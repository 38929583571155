import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            {/*<PageBanner
                pageTitle="Terms of Service"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Terms of Service"
            />*/}
            <section className="terms-of-service-area pt-150 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                              <img src={termsImg} alt="tt" />
                              <div className="terms_inner_body">
                                  END USER LICENSE AGREEMENT
                                  <p>
                                  BY ACCESSING OR USING OUTSPOKE IO, YOU AGREE TO ALL OF THE FOLLOWING PROVISIONS AND ENTER INTO A LEGALLY BINDING AGREEMENT WITH OUTSPOKE IO TO ABIDE BY THE PROVISIONS OF THIS AGREEMENT. OUTSPOKE IO RESERVES THE RIGHT TO MODIFY THIS AGREEMENT AT ANY TIME BY POSTING SUCH MODIFICATIONS TO THIS WEBSITE. YOUR CONTINUED ACCESS TO OR USE OF THIS WEBSITE AFTER SUCH POSTINGS WILL SIGNIFY YOUR AGREEMENT TO SUCH MODIFICATIONS, IF ANY.
                                  </p><p style={{fontSize: 14}}>
                                  These Terms of Service ("Terms") cover your use of and access to the sites, templates, products, applications, tools and features (collectively, the "Services") provided by OUTSPOKE IO (“OS, Company”), including without limitation during free trials, on the website and associated domains of www.outspoke.io and on www.outspoke.io web and mobile applications.
                                  </p><p style={{fontSize: 14}}>
                                  By using or accessing the Services, you're agreeing to these Terms (“Agreement”) If you're using the Services for an organization, you're agreeing to this Agreement on behalf of that organization, and you represent and warrant that you can do so. References to “you”, "your” and similar terms are construed accordingly in this Agreement. If you don’t agree to all the terms in this Agreement, you may not use or access the Services.
                                  </p><p style={{fontSize: 14}}>
                                  If you are a resident of or have your principal place of business in the United States of America or any of its territories or possessions (the “US”), you are agreeing to this Agreement with Outspoke IO and are a “US User”.
                                  Please read this Agreement carefully! It includes important information about your legal rights, and covers areas such as automatic subscription renewals, warranty disclaimers, limitations of liability, the resolution of disputes by arbitration and a class action waiver.
                                  </p><p style={{fontSize: 14}}>
                                  We’ve tried to make this Agreement fair and straightforward, but feel free to contact Outspoke IO if you have any questions or suggestions.
                                  </p><p style={{fontSize: 14}}>
                                  1. Creating Account.
                                  </p><p style={{fontSize: 14}}>
                                  1.1. Signing Up.
                                  </p><p style={{fontSize: 14}}>
                                  To use the Services, you must first create a free user account (“Free Account”). You agree to provide OS with accurate, complete information and keep this information updated.  We may need to use this information to contact you or to ensure you have permission to act.
                                  </p><p style={{fontSize: 14}}>
                                  1.2. Staying Safe.
                                  </p><p style={{fontSize: 14}}>
                                  Please safeguard your Account and make sure others don't have access to your Account or password. You're solely responsible for any activity on your Account and for maintaining the confidentiality and security of your password. We’re not liable for any acts or omissions by you in connection with your Account. You must immediately notify us if you know or have any reason to suspect that your Account or password have been stolen, misappropriated or otherwise compromised or in case of any actual or suspected unauthorized use of your Account.
                                  </p><p style={{fontSize: 14}}>
                                  1.3. Twenty-One and Older.
                                  </p><p style={{fontSize: 14}}>
                                  The services provided herein are designed for adults 21 years of age or older. Users under the age of 21 are not permitted to use the website. By using the website, you agree to provide Outspoke IO with accurate information concerning your age or identity as requested by Outspoke IO You also agree not to assist children under the age of 21 in accessing the website.
                                  </p><p style={{fontSize: 14}}>
                                  1.4. Upgrading from Free to Paid Account
                                  </p><p style={{fontSize: 14}}>
                                  Many services and tools offered by OS require you to upgrade your Free Account and includes a required payment, which varies depending on the services and is subject to change without notice.  Your new account (“Paid Account”) may require different memberships or payments to access different set of tools; by upgrading to a Paid Account you understand and agree to pay all charges associated with access to tools, services or related data charges connected to your use and access.  You also agreed to and understand that information easily available to the public, a Free Account user or a Paid Account user maybe used by OS to market OS’s services and benefits or be used in a manner in line with the rest of this agreement.  By having a Free Account or Paid Account, you agree to provide OS with an irrevocable global license on all data entered, generated or interpreted by OS technology, employees, contracts or otherwise.
                                  </p><p style={{fontSize: 14}}>
                                  2. Scope Of License.
                                  </p><p style={{fontSize: 14}}>
                                  Subject to your compliance with these Conditions of the Terms of service, and your payment of any applicable fees, Outspoke IO or its content providers grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of Outspoke IO. This license does not include any resale or commercial use of any of our services, products, or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of any Outspoke IO service or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Terms of Service are reserved and retained by Outspoke IO, its affiliates or its licensors, suppliers, publishers, rightsholders, or other content providers. No Outspoke IO service, nor any part of any Outspoke IO, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Outspoke IO. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Outspoke IO without express written consent. You may not use any meta tags or any other "hidden text" utilizing Outspoke IO’s name or trademarks without the express written consent of Outspoke IO. You may not misuse the Outspoke IO services. You may use Outspoke IO service only as permitted by law. The licenses granted by Outspoke IO terminate if you do not comply with these Conditions of Use or any Service Terms.
                                  </p><p style={{fontSize: 14}}>
                                  3.    Paid Services and Fees.
                                  Certain Services are only available as paid services. This section explains how we handle payments for those paid services. For certain paid services, such as domain registrations and website subscriptions, we’ll automatically bill you in regular intervals (such as monthly or annually) unless you disable auto-renewal or cancel your subscription. You can do that anytime.
                                  </p><p style={{fontSize: 14}}>
                                  3.1. Fees.
                                  You can access certain portions of the Services by submitting a fee payment (such additional services, “Paid Services”). Paid Services will remain in effect until cancelled or terminated in accordance with this Agreement. We’ll tell you about fees for Paid Services before charging you. You may cancel Paid Services at any time via the Services. If you don't pay for Paid Services on time, we reserve the right to suspend or cancel your access to the Paid Services. Transaction fees and additional fees may also apply to certain portions of the Services, and we’ll tell you about those fees before charging you. Our fees will appear on an invoice that we provide via the Services, within your eCommerce Payment Processor account(s) and/or on a mobile app store invoice, unless otherwise indicated. Please note that different Paid Services have different fees and payment schedules and canceling one Paid Service may not cancel all your Paid Services. SEE APPENDIX A FOR A SCHEDULE OF YOUR FEES AND COSTS ASSOCIATED WITH YOUR MEMBERSHIP TO OUTSPOKE IO.
                                  </p><p style={{fontSize: 14}}>
                                  3.2. Taxes.
                                  All fees are exclusive of applicable national, provincial, state, local or other taxes (“Taxes”), unless explicitly stated otherwise. You're responsible for all applicable Taxes, and we'll charge Taxes in addition to the fees for the Services when required to do so. If you're exempt from Taxes, you must provide us with valid tax exemption documentation. We reserve the right to determine if the documentation provided is valid. Tax exemption will, provided we’re satisfied its valid and applicable, only apply from and after the date we receive such documentation. If Outspoke IO has a legal obligation to pay or collect indirect Taxes (such as value-added tax or goods and services tax) on the sale to you under the laws of your country (where you are established, have a permanent address or usually reside), you shall be liable for payment of any such indirect Taxes. Where Outspoke IO does not have a legal obligation to pay or collect indirect Taxes on a sale of Paid Services to you, you may be required to self-assess those Taxes under the applicable laws of your country (where you are established, have a permanent address or usually reside).
                                  </p><p style={{fontSize: 14}}>
                                  3.3. Automatic Subscription Renewals.
                                  To ensure uninterrupted service, we'll automatically bill you for certain Paid Services from the date you submit your initial payment and on each renewal period afterwards until cancellation. Your renewal period will be equal in time to the renewal period of your current subscription. For example, if you're on a monthly subscription plan, each billable renewal period will be for one (1) month. We’ll automatically charge you the applicable amount using the payment method you have on file with us. We’ll let you know in advance if you’re purchasing a Paid Service that includes auto-renewal payments.
                                  </p><p style={{fontSize: 14}}>
                                  3.4. Refunds.
                                  While you may cancel any Paid Services at any time, you won't be issued a refund except in our sole discretion, or if legally required. We offer a free trial, so you can try out your website subscription. Please note applicable statutory rights of cancellation may not result in a refund, as we do not charge for this trial period.
                                  </p><p style={{fontSize: 14}}>
                                  3.5. Fee Changes.
                                  We may change our fees at any time. We’ll provide you with advance notice of these fee changes via the Services. New fees will not apply retroactively. If you don't agree with the fee changes, you have the right to reject the change by canceling the applicable Paid Service before your next payment date.
                                  </p><p style={{fontSize: 14}}>
                                  3.6. Chargebacks.
                                  If you contact your bank or credit card company to decline, chargeback or otherwise reverse the charge of any payable fees to us (“Chargeback”), we may automatically terminate your Account. If you have questions about a payment made to us, we encourage you to contact us before filing a Chargeback. We reserve our right to dispute any Chargeback.
                                  </p><p style={{fontSize: 14}}>
                                  3.7. Processing Payments.
                                  Payments will be processed by the distributors or retailers purchasing the products on the website. These payments will link to Outspoke IO application processing interface where we will collect data and information relating to the sales in order to better manage our website.  The processing of payments will be subject to the terms, conditions and privacy policies of their Payment Processor, in addition to this Agreement.  You agree to pay us, through their processor all charges at the prices then in effect for any purchase in accordance with the applicable payment terms. You agree to make payment using the payment method you provide with your Account. We reserve the right to correct, or to instruct their Payment Processor to correct, any errors or mistakes, even if payment has already been requested or received.
                                  </p><p style={{fontSize: 14}}>
                                  3.8. Fees For Third Party Services.
                                  Third Party Services purchased via the Services may be subject to different refund policies that those Third Party Services determine, and they may be non-refundable. The purchase terms and conditions for such Third Party Services may be displayed during the purchase process, such as through a link to the purchase terms and conditions. It's your responsibility to verify your ability to purchase, cancel or obtain a refund for a Third Party Service. Unless otherwise stated in this Agreement, we don’t offer refunds for purchases of Third Party Services.
                                  </p><p style={{fontSize: 14}}>
                                  4.    Your eCommerce On Outspoke IO.
                                  We offer tools to help you conduct eCommerce activities on Outspoke IO such as selling your products or collecting donations. How you conduct your eCommerce activities is your responsibility, and we’re not liable for it. Also, be sure to follow our eCommerce rules, or we may terminate your account. Finally, when you use a third party to process payments for your eCommerce activities, remember that your relationship is with them, not us.
                                  </p><p style={{fontSize: 14}}>
                                  4.1. eCommerce Responsibilities.
                                  The Services include features that enable you to provide or sell products and services to, or otherwise collect payments from, your End Users (such activities, “Your eCommerce”). We’re not a party to, and we aren’t liable for, Your eCommerce. You're solely responsible for Your eCommerce, and compliance with any laws or regulations related to it, including without limitation the following:
                                  </p><p style={{fontSize: 14}}>
                                  4.1.1. Taxes.
                                  You're solely responsible for: (a) all Taxes and fees associated with Your eCommerce, including without limitation any Taxes related to the purchase or sale of products or services in connection with Your eCommerce; (b) collecting, reporting and remitting required Taxes to relevant government authorities; (c) informing your End Users of required Taxes, and providing them with invoices as required by applicable law. You also agree that any tax estimates, reporting or related materials that we may provide via the Services are for illustration purposes only, and you may not rely on them to comply with your tax obligations. We do not give tax advice, and nothing we say should be interpreted as such.
                                  </p><p style={{fontSize: 14}}>
                                  4.1.2. Fulfillment And Delivery.
                                  You're solely responsible for fulfilling and delivering your products and services to your End Users.
                                  </p><p style={{fontSize: 14}}>
                                  4.1.3. Claims And Warranties.
                                  You're solely responsible for any claims or warranties you make in connection with Your eCommerce and any claims made by End Users against you.
                                  </p><p style={{fontSize: 14}}>
                                  4.1.4. Customer Service.
                                  You're solely responsible for handling any comments or complaints related to Your eCommerce, including without limitation any issues related to payments, promotions, refunds or chargebacks. You agree to provide accurate and complete contact information on Your websites so that your End Users can submit comments or complaints to you.
                                  </p><p style={{fontSize: 14}}>
                                  4.1.5. Website Terms, Policies And Legal Compliance.
                                  You agree to post and make clearly available on Your websites a privacy and cookie policy, and any other terms or policies that may be required by applicable law, and you warrant that Your websites and Your eCommerce and your conduct will comply with all applicable laws and regulations. You understand and agree that your use is through that of a license to access Outspoke IO and all actions performed within can be monitored and recorded for the purposes of compliance, legal, or any other reason as decided by OutspokeIO.  You agree that we won’t provide any legal advice regarding such terms, policies or compliance.
                                  </p><p style={{fontSize: 14}}>
                                  4.1.6. Consumer, eCommerce And Other Laws.
                                  You are also responsible for complying with any consumer, eCommerce and related laws.
                                  </p><p style={{fontSize: 14}}>
                                  4.2. eCommerce Restrictions.
                                  You may not offer or sell any products or services which, in our sole discretion,: (a) we consider hazardous, counterfeit, stolen, fraudulent, abusive or adverse to our interests or reputation; (b) are prohibited for sale, distribution or use; or (c) otherwise fail to comply with any applicable laws or regulations, including without limitation with respect to intellectual property, trade secrets, privacy or publicity rights, consumer protection, product safety or trade regulations or export controls, regulations or sanctions.
                                  </p><p style={{fontSize: 14}}>
                                  4.3. eCommerce Suspensions.
                                  While we’d prefer not to, we may, at any time and in our sole discretion, and without any notice to you, suspend, restrict or disable access to or remove your Account, your websites or Your eCommerce, without any liability to you or to any End Users, including without limitation for any loss of profits, revenue, data, goodwill or other intangible losses (except where prohibited by applicable law). For example, we may suspend Your eCommerce if you’re violating this Agreement.
                                  </p><p style={{fontSize: 14}}>
                                  4.4. eCommerce Payment Processors.
                                  To accept payments from your End Users in connection with Your eCommerce, you may integrate Your websites with third party payment processors (“eCommerce Payment Processors”). Your relationship with such eCommerce Payment Processors is governed by those eCommerce Payment Processors’ terms and policies. We don’t control and aren’t liable for any eCommerce Payment Processors, or for any transaction you may enter into with or through any eCommerce Payment Processors. eCommerce Payment Processors are a Third Party Service, as defined in Section 5.1. While we will try to provide advance notice, you agree that we may, at any time and in our sole discretion, and without any notice to you, suspend, restrict or disable access to or remove from the Services, any eCommerce Payment Processors, without any liability to you or to any End Users, including without limitation for any loss of profits, revenue, data, goodwill or other intangible losses (except where prohibited by applicable law). Your eCommerce Payment Processors may provide invoices for any transaction fees associated with Your eCommerce transactions.
                                  </p><p style={{fontSize: 14}}>
                                  5. Your Content, Products and Company Listings.
                                  </p><p style={{fontSize: 14}}>
                                  5.1. Your User Content Stays Yours.
                                  Users of the Services (whether you or others) may provide us with content, including without limitation text, photos, images, audio, video, code and any other materials (“User Content"). Your User Content stays yours, except for the limited rights that enable us to provide, improve, promote and protect the Services as described in this Agreement.
                                  </p><p style={{fontSize: 14}}>
                                  5.2. Your License To Us.
                                  When you provide User Content via the Services, you grant Outspoke IO (including our third party hosting providers acting on our behalf) a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable, transferable right and license to use, host, store, reproduce, modify, create derivative works of (such as those resulting from translations, adaptations or other changes we make so that User Content works better with the Services), communicate, publish, publicly display, publicly perform and distribute User Content for the limited purposes of allowing us to provide, improve, promote and protect the Services. This Section does not affect any rights you may have under applicable data protection laws.
                                  </p><p style={{fontSize: 14}}>
                                  5.3. Featuring Your Products.
                                  By accepting this Agreement, you agree that your products and company listing will be listed on our website based on an algorithm which has been developed by Outspoke IO, and exclusively owned by Outspoke IO, which consists of numerous factors, to ensure that our End Users are best provided with the products they are most likely to purchase. This algorithm is based purely on data and projected data, determined to be relevant solely by Outspoke IO.
                                  Outspoke IO, in addition to using its algorithm, has the right to list products in any order, based on a fee-based model where Distributors and Retailers can pay and additional fee for advertising while will allow them to be more prominently listed on the website.  The Distributors and Retailers who do not pay the additional fee for to be listed more prominently on our website will be listed on the website based on our algorithm as stated above.
                                  We may choose to feature products you use on our website or names, trademarks, service marks or logos you place on our website or on our social media or in print to promote these products in addition to promoting Outspoke IO. You grant us a perpetual, worldwide, royalty-free, non-exclusive right and license to use any version of your product, or any portion of Your products, including without limitation names, trademarks, service marks or logos on Your products, for the limited purpose of Outspoke IO marketing and promotional activities. You waive any claims against us relating to any moral rights, artists’ rights or any other similar rights worldwide that you may have in or to your products or names, trademarks, service marks or logos on our website and any right of inspection or approval of any such use. You can opt out of being featured through your Account. This Section does not affect any rights you may have under applicable data protection laws.
                                  </p><p style={{fontSize: 14}}>
                                  6. Intellectual Property Rights.
                                  Outspoke IO is the sole owner of all intellectual property rights pertaining to the software, algorithm, applications and services, including i.e.. copyright, database right and other intellectual property rights to e.g. software, applications, images and other materials (including documentation, compilations and information) that is provided to the user by Outspoke IO as well as all amendments, corrections, developments, translations and copies thereof.
                                  Outspoke IO doesn’t not transfer any intellectual property rights to the Customer through the agreement. Through the agreement, Outspoke IO only grants users the right to access the site and the information container therein.
                                  </p><p style={{fontSize: 14}}>
                                  7. Password and Security.
                                  You are solely responsible for maintaining the confidentiality of any user name and password you use to access the Website and are fully responsible for all activities that occur under your password or account. You agree to (i) notify Outspoke IO immediately of any unauthorized use of your password or account or any other breach of security and (ii) to ensure that you exit from your account at the end of each session. Outspoke IO cannot and will not be liable for any loss or damage arising from your failure to comply with this provision.
                                  </p><p style={{fontSize: 14}}>
                                  8. Linker Websites and Advertising.
                                  Outspoke IO may provide links to the websites or services of third-party sites. Links to such third-party sites do not constitute and endorsement by Outspoke IO of such sites or the products, content, materials or information presented or made available to such sites. You acknowledge and agree that Outspoke IO is not responsible for any damage or losses caused or alleged to have been caused by the use of any third-party sites or from the products, content, material, or information presented by or available through those sites.
                                  The website and its content (but not the content of third-party sites are hosted by Outspoke IO and independent Outspoke IO corporation.
                                    </p><p style={{fontSize: 14}}>
                                  9. Disclosure of Affiliations.
                                  Outspoke IO works with a variety of third parties to provide the services available on this website and to provide links to other websites. These third parties include producers, retailers, distributors, suppliers of products, information and services. These entities may supply information that is posted on or accessible through the website. While Outspoke IO has these affiliations, they do not control the content or the operation of the website.
                                  </p><p style={{fontSize: 14}}>
                                  10. Children and International Users.
                                  The Outspoke IO website is designed for adults 18 years of age or older. Users under the age of 18 are not permitted to use the website. By using the website, you agree to provide Outspoke IO with accurate information concerning your age or identity as requested by Outspoke IO. You also agree not to assist children under the age of 18 in accessing the website.
                                  If you are not a U.S. resident, you may access the Website solely at your own risk and are responsible for compliance with local laws and regulations, if applicable. By disclosing your personal information to the website, you consent to the uses of such information and acknowledge that such disclosures and used shall be governed by the applicable law of the United States and the State of California and not the law of your home country. Do not disclose any information to the Website if you do not agree to the foregoing.
                                  </p><p style={{fontSize: 14}}>
                                  11. Disclaimer of Warranties.
                                  THE WEBSITE AND ALL SERVICES AND INFORMATION MADE AVAILABLE ON OR THROUGH THIS WEBSITE ARE PROVIDED ON AND “AS IS,” “WHERE AS,” AND “AS AVAILABLE” BASIS WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY OR INFORMATIONAL CONTENT. Without limiting the foregoing Outspoke IO makes not representations or warranties about the accuracy, reliability, completeness or timeliness of the content, software, text, graphics, products or communications provided on or through the use of the website.
                                  When using the Website, information will be transmitted over a medium that may be beyond Outspoke IO’s control and jurisdiction. Accordingly, Outspoke IO does not assume any liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the website.
                                  OUTSPOKE IO DOES NOT WARRANT THAT THIS WEBSITE OR ANY SERVICES OR INFORMATION ARE AVAILABLE ON OR THROUGH THIS WEBSITE, INCLUDING WITHOUT LIMITATION THE ACTIONS OF ANY THIRD PARTY, PRODUCTS, ITEMS OR OTHER MATERIALS USED IN CONNECTION WITH THIS WEBSITE, WILL BE TIMELY, SECURE, UNITERRUOTED OR ERROR FREE OR THAT DEFECTS WILL BE CORRECTED, THIS INCLUDES SPECIFICALLY ANY FEATURES WHICH UPDATE ON ANY REGULAR BASIS, WHICH INCLUDES ANY DATA, ANALYTICS OR OTHERWISE, WHETHER PROVIDED BY INTERNAL DATA OR FEATURES OR 3RD PARTY DATA OR FEATURES WHETHER FROM METRC OR ANY OTHER PLATFORM OR DATA SOURCE, VIRTUAL OR TANGIBLE.
                                  </p><p style={{fontSize: 14}}>
                                  12. Limitation of Liability.
                                  You acknowledge and agree that to the fullest extent permitted by applicable law, in no event will Outspoke IO and its affiliates and its and their directors, officers, employees and agents be liable with respect to any claims arising out of or related to the Services or this Agreement for: (a) any indirect, special, incidental, exemplary, punitive or consequential damages; (b) any loss of profits, revenue, data, goodwill or other intangible losses; (c) any damages related to your access to, use of or inability to access or use parts, some or all of  your Account, Your Sites or parts or all of the Services, including without limitation interruption of use or cessation or modification of any aspect of the Services; (d) any damages related to unavailability, degradation, loss, corruption, theft, unauthorized access or, unauthorized alteration of, any content, information or data, including without limitation User Content and Your eCommerce data; (e) any User Content or other conduct or content of any user or third party using the Services, including without limitation defamatory, offensive or unlawful conduct or content; or (f) any Third Party Services or third party sites accessed via the Services.
                                  </p><p style={{fontSize: 14}}>
                                  13. Indemnification.
                                  To the fullest extent permitted by law, you agree to indemnify and hold harmless Outspoke IO and its affiliates and its and their directors, officers, employees and agents from and against all damages, losses, liabilities, costs, claims, demands, fines, awards and expenses of any kind (including without limitation reasonable attorneys' fees and costs) arising out of or related to: (a) your breach of this Agreement; (b) your User Content, Your Sites and Your eCommerce; (c) any claims by, on behalf of or against your End Users; (d) your violation of any law or regulation or the rights or good name of any third party; and (e) any claims from tax authorities in any country in relation to Your eCommerce operations, including without limitation your sales to individual consumers (including distance sales) and other operations for which Outspoke IO may be held jointly and severally liable. Your indemnification obligations under this Section shall not apply to the extent directly caused by our breach of this Agreement.
                                  </p><p style={{fontSize: 14}}>
                                  14. Dispute Resolution.
                                  This Section 12 may not apply to you. If it does, before filing a claim against Outspoke IO you agree to try to work it out informally with us first. Also, all formal disputes must be resolved through arbitration following the rules described below, unless you opt out of arbitration following the procedure described below. Finally, claims can only be brought individually, and not as part of a class action.
                                  </p><p style={{fontSize: 14}}>
                                  14.1 Applicability.
                                  This Section 12 shall only apply to: (a) US Users who bring any claim against Outspoke IO in the US (to the extent not in conflict with Section 14).
                                  </p><p style={{fontSize: 14}}>
                                  14.2 Informal Resolution.
                                  Before filing a claim against Outspoke IO you agree to try to resolve the dispute by first emailing info@outcrowd.group with a description of your claim. We'll try to resolve the dispute informally by following up via email, phone or other methods. If we can’t resolve the dispute within thirty (30) days of our receipt of your first email, you or Outspoke IO may then bring a formal proceeding.
                                  </p><p style={{fontSize: 14}}>
                                  14.3. Arbitration Agreement.
                                  Unless you opt-out during the Opt-Out Period in accordance with Section 14.4, you and Outspoke IO agree to resolve any claims, disputes and matters arising out of or in connection with this Agreement (including without limitation its existence, formation, operation and termination) and/or the Services (including without limitation non-contractual disputes and matters) through final and binding arbitration and you and Outspoke IO expressly waive the right to formal court proceedings (including without limitation trial by jury), except as set forth below. Discovery and rights to appeal in arbitration are generally more limited than in a lawsuit, and other rights that you and we would have in court may not be available in arbitration. There is no judge or jury in arbitration, only an independent third party that will act as the arbitrator, and court review of an arbitration award is limited.
                                  </p><p style={{fontSize: 14}}>
                                  14.4. Arbitration Opt-Out.
                                  You can decline this agreement to arbitrate by emailing us at info@outcrowd.group within thirty (30) days of the date that you first agree to this Agreement (“Opt-Out Period”). Your email must be sent from the email address you use for your Account, and must include your full name, residential address and a clear statement that you want to opt out of arbitration. If you opt out of arbitration pursuant to this Section 14.4, then Sections 14.3, 14.5, 14.6 and 14.7 of these Terms do not apply to you. This opt-out doesn’t affect any other sections of the Terms, including without limitation Section 14 (Controlling Law). If you have any questions about this process, please contact info@outcrowd.group.
                                  </p><p style={{fontSize: 14}}>
                                  14.5. Arbitration Time For Filing.
                                  Any arbitration must be commenced by filing a demand for arbitration within one (1) year after the date the party asserting the claim first knows or reasonably should know of the act, omission or default giving rise to the claim. If applicable law prohibits a one (1) year limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law.
                                  </p><p style={{fontSize: 14}}>
                                  14.6. Arbitration Procedures.
                                  JAMS, Inc. (“JAMS”) will administer the arbitration in accordance with the JAMS Streamlined Arbitration Rules & Procedures (“JAMS Rules”) in effect at the time of the dispute.
                                  </p><p style={{fontSize: 14}}>
                                  14.6.1. US Users.
                                  If you are a US User, you and Outspoke IO agree that this Agreement affects interstate commerce, so the US Federal Arbitration Act and federal arbitration law apply and govern the interpretation and enforcement of these provisions. Any arbitration hearings will take place at a location to be agreed upon in San Francisco, California in English, and shall be settled by one (1) commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the JAMS Rules. The arbitrator must follow this Agreement and can award the same damages and relief as a court (including without limitation reasonable attorneys' fees and costs), except that the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.
                                  </p><p style={{fontSize: 14}}>
                                  14.7. Arbitration Fees.
                                  The JAMS Rules will govern payment of all arbitration fees. We won’t seek our attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.
                                  </p><p style={{fontSize: 14}}>
                                  15.    Privacy.
                                  Our Privacy Policy explains how we collect, use and share your personal information for our own purposes. Be sure to read it carefully, but note it is not part of this Agreement and can change. It is important that you comply with data protection laws when using the services, such as when you collect others’ personal information or use cookies or similar technologies (including those we drop for you at your request, such as for web analytics).
                                  </p><p style={{fontSize: 14}}>
                                  15.1. Privacy Policy.
                                  By using the Services, you confirm that you have read and understood our Privacy Policy. However, it is not a contractual document and does not form part of this Agreement and we may change it from time to time.
                                  </p><p style={{fontSize: 14}}>
                                  15.2. You Must Comply With Data Protection, Security And Privacy Laws.
                                  You agree and warrant that you are solely responsible when using Your Sites or the Services for complying with applicable data protection, security and privacy laws and regulations, including any notice and consent requirements. This includes without limitation the collection and processing by you of any personal data, when you use Your Sites and the Services to send marketing and other electronic communications to individuals and when using cookies and similar technologies on Your Sites (including, in particular, those which we place for you at your request as part of the Services, such as to undertake analytics for you).
                                  </p><p style={{fontSize: 14}}>
                                  15.3.1. Privacy Policies.
                                  If applicable law requires, you must provide and make available to your End Users on Your Sites a legally compliant privacy policy.
                                  </p><p style={{fontSize: 14}}>
                                  15.3.2. Cookies And Similar Technologies.
                                  If applicable law requires, you must provide and make available to your End Users on Your Sites a legally compliant cookie policy. You must capture valid consent, both for you and us, for any cookies or similar technologies used on or through Your Site (including those we drop on your request or with your permission) where required.
                                  </p><p style={{fontSize: 14}}>
                                  15.4. Protect And Improve The Services.
                                  You agree that we may protect and improve our Services through analysis of your use of the Services, your End Users’ use of Your Sites and/or analysis of your and your End Users’ personal information in anonymized, pseudonymized, de-personalized and/or aggregated form. If applicable law requires, you should explain this in your privacy policy. See our Privacy Policy for more information about how and what we do in this regard.
                                  </p><p style={{fontSize: 14}}>
                                  16. Licensing.
                                  You are solely responsible with complying with all state and local laws requiring licensing and any other applicable laws, rules and regulations regarding your business. Unless otherwise allowed by law, you must possess valid licensing to purchase and/or sell the products featured on this Site. A valid license is always required and must be provided to Outspoke IO prior to joining the Website in any capacity, except for non-license holders using the service as a means to white label products through. If license has not been updated any sales made through the non-licensed user will not be completed and fees associated for use will not be returned. If you cannot provide a valid license you will not be allowed entry onto the site. Further if your license has expired or is in some way invalid, then you will be removed from the website at the sole discretion of Outspoke IO. If it is determined that your license is not authentic, you will be immediately removed from the site and the proper authorities will be notified. Outspoke IO is in no way responsible should it be determined that a license presented to the site is inauthentic, you are responsible for producing a valid licensing. Outspoke IO reserves the right to terminate its relationship with you, at our sole discretion, in addition to the reasons stated above, if your license does not appear to be authentic.
                                  </p><p style={{fontSize: 14}}>
                                  17. Entire Agreement.
                                  This Agreement constitutes the entire agreement between you and Outspoke IO regarding the subject matter of this Agreement and supersedes and replaces any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of this Agreement. You agree that you have not relied upon, and have no remedies in respect of, any term, condition, statement, warranty or representation except those expressly set out in this Agreement. You also may be subject to additional terms, policies or agreements that may apply when you use other services, including Third Party Services. This Agreement creates no third-party beneficiary rights.
                                  </p><p style={{fontSize: 14}}>
                                  18. Governing Law.
                                  If you are a US User, this Agreement (including its existence, formation, operation and termination) and the Services as well as all disputes and matters arising out of or in connection with this Agreement and the Services (including non-contractual disputes and matters) shall be governed in all respects by the laws of the State of New York, without regard to its conflict of law provisions. If Section 12 is found not to apply to you or your claim, or if you opt out of arbitration pursuant to Section 12.4, you and Outspoke IO agree that any judicial proceeding (other than small claims actions) arising out of or in connection with this Agreement (including its existence, formation, operation and termination) and/or the Services (including non-contractual disputes and matters) must be brought exclusively in the federal or state courts of California and you and Outspoke IO consent to venue and personal jurisdiction in such courts.
                                  </p><p style={{fontSize: 14}}>
                                  19. Waiver, Severability And Assignment.
                                  Our failure to enforce any provision of this Agreement is not a waiver of our right to do so later. If any provision of this Agreement is found unenforceable, the remaining provisions will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not delegate, transfer or assign this Agreement or any of your rights or obligations hereunder without our prior written consent, and any such attempt will be of no effect. We may delegate, transfer or assign this Agreement or some or all of our rights and obligations hereunder, in our sole discretion, to any of our affiliates or subsidiaries or to any purchaser of any of our business or assets associated with the Services, with thirty (30) days prior written notice.
                                  </p><p style={{fontSize: 14}}>
                                  20. Events Beyond Our Control.
                                  We are not in breach of this Agreement or liable to you if there is any total or partial failure of performance of the Services resulting from any act, circumstance, event or matter beyond our reasonable control. This may include where such results from any act of God, fire, act of government or state or regulation, war, civil commotion, terrorism, insurrection, inability to communicate with third parties for whatever reason, failure of any computer dealing or necessary system, failure or delay in transmission of communications, failure of any internet service provider, strike, industrial action or lock-out or any other reason beyond our reasonable control.
                                  </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="#">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {/*<div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                    <Link to="#">Business <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                    <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                    <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div>*/}
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;
